import React, { useState, useEffect } from 'react';

import slide1 from "../images/slides/1.jpg";
import slide2 from "../images/slides/2.jpg";
import slide3 from "../images/slides/3.jpg";
import slide4 from "../images/slides/4.jpg";
import slide5 from "../images/slides/5.jpg";
import slide6 from "../images/slides/6.jpg";
import slide7 from "../images/slides/7.jpg";
import slide8 from "../images/slides/8.jpg";
import slide9 from "../images/slides/9.jpg";
import slide10 from "../images/slides/10.jpg";
import slide11 from "../images/slides/11.jpg";
import slide12 from "../images/slides/12.jpg";
import slide13 from "../images/slides/13.jpg";
import slide14 from "../images/slides/14.jpg";
import slide15 from "../images/slides/15.jpg";
import slide16 from "../images/slides/16.jpg";
import slide17 from "../images/slides/17.jpg";
import slide18 from "../images/slides/18.jpg";
import slide19 from "../images/slides/19.jpg";
import slide20 from "../images/slides/20.jpg";
import slide21 from "../images/slides/21.jpg";
import slide22 from "../images/slides/22.jpg";

const SlideShowBox = () => {
/*
  const slide1 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/1.jpg";
  const slide2 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/2.jpg";
  const slide3 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/3.jpg";
  const slide4 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/4.jpg";
  const slide5 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/5.jpg";
  const slide6 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/6.jpg";
  const slide7 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/7.jpg";
  const slide8 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/8.jpg";
  const slide9 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/9.jpg";
  const slide10 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/10.jpg";
  const slide11 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/11.jpg";
  const slide12 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/12.jpg";
  const slide13 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/13.jpg";
  const slide14 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/14.jpg";
  const slide15 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/15.jpg";
  const slide16 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/16.jpg";
  const slide17 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/17.jpg";
  const slide18 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/18.jpg";
  const slide19 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/19.jpg";
  const slide20 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/20.jpg";
  const slide21 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/21.jpg";
  const slide22 = "http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/bionic_momentum/images/slides/22.jpg";*/
    
  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = () => {
    let number = slideIndex;
    number++;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const minusSlides = () => {
    let number = slideIndex;
    number--;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const currentSlide = (n) => {
    setSlideIndex(n);
    showSlides(n);
  }

  const showSlides = (n) => {
    let i;
    let numOfSlides = 22;
    let number = 0 + n;

    const slides = [];
    for (i = 0; i < numOfSlides; i++) {
        slides.push(document.getElementById("slide01" + i + "-" + 1));
    }

    const dots = [];
    for (i = 0; i < numOfSlides; i++) {
        dots.push(document.getElementById("dot01" + i + "-" + 1));
    }

    if (n > numOfSlides) {
        setSlideIndex(1);
        number = 1;
    }    
    if (n < 1) {
        setSlideIndex(numOfSlides);
        number = numOfSlides;
    }
    for (i = 0; i < numOfSlides; i++) {
        slides[i].style.display = "none";  
    }
    for (i = 0; i < numOfSlides; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    number--;
    slides[number].style.display = "block";  
    dots[number].className += " active";
  }


  useEffect(() => {
    window.setTimeout ( function() {   
      currentSlide(0); 
      window.setTimeout ( function() {   
        currentSlide(1); 
      }, 100);
    }, 100);

  }, []);

  const makeDots = () => {
    const row = [];
    for (var i = 0; i < 22; i++) {
      row.push(<span key={i} id={"dot01" + i + "-" + 1} className="dot"></span> );
    }
    return row;
  };

  const makeSlides = () => {
    const slides = [];
    slides.push(slide1);
    slides.push(slide2);
    slides.push(slide3);
    slides.push(slide4);
    slides.push(slide5);
    slides.push(slide6);
    slides.push(slide7);
    slides.push(slide8);
    slides.push(slide9);
    slides.push(slide10);
    slides.push(slide11);
    slides.push(slide12);
    slides.push(slide13);
    slides.push(slide14);
    slides.push(slide15);
    slides.push(slide16);
    slides.push(slide17);
    slides.push(slide18);
    slides.push(slide19);
    slides.push(slide20);
    slides.push(slide21);
    slides.push(slide22);

    const row = [];
    for (var i = 0; i < 22; i++) {
      row.push(<div key={i} id={"slide01" + i + "-" + 1} className="mySlides">
      <img src={slides[i]} className="w3-hover-opacity2 shadow-child" alt="Not found" style={{width: "100%", height: "100%"}}></img>
    </div> );
    }
    return row;
  };


  const closeBox = (e) => {

    if (e.target instanceof HTMLImageElement || e.target instanceof HTMLAnchorElement)
    {
        return;
    } 

    window.setTimeout ( function() {  
      document.getElementById("slideshow-box").style.display = "none";
      }, 50);

  }

  return (
    <div  onClick={(e) => closeBox(e)} id="slideshow-box">
        <button  onClick={(e) => closeBox(e)} style={{position:"fixed", top:0, right:0, zIndex:"200", height:"60px", width:"60px", fontSize: "30px"}}> X </button>
      <div id="slideshow-box-container">
        <div id="slideshow-box-center" className="w3-container slideshow-box-padding" >
        <a className="prev" style={{paddingLeft: "2%", transform:"scale(1.5)"}} onClick={() => minusSlides()}>❮</a>
                <a className="next" style={{paddingRight: "2%", transform:"scale(1.5)"}} onClick={() => plusSlides()}>❯</a>

            <div className="product-image2">
                  <div className="slideshow-container shadow-parent">
                    {
                      makeSlides()
                    }
                  </div>
                  <br></br>
                  <div style={{position: "absolute", textAlign: "center", bottom: "15px", left: "30%", right: "30%"}}>
                    {
                      makeDots()
                    }
                </div>
            </div>
      </div>
      </div>
    </div>
  )
}

export default SlideShowBox
