import { useState } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";

const Contact = ({language}) => {

  const titleEN = "CONTACT FORM";
  const titleDE = "KONTAKT";

  const subtitleEN = "Send us a message:";
  const subtitleDE = "Schick uns eine Nachricht:";

  const locationEN = " Location: Germany";
  const locationDE = " Standort: Deutschland";

  const nameEN = "Name";
  const nameDE = "Name";

  const emailEN = "Your E-Mail";
  const emailDE = "Deine E-Mail";
  
  const subjectEN = "Subject";
  const subjectDE = "Thema";
  
  const textEN = "Enter message";
  const textDE = "Text eingeben";

  const btnEN = " SEND MESSAGE";
  const btnDE = " NACHRICHT SENDEN";

  const { register, handleSubmit } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  const [emailError, setEmailError] = useState('');

  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")


  const onSubmit = async (e) => {
    //e.preventDefault();

    if (validator.isEmail(mail)) {
      setEmailError('');
      
      const config = {
        method: 'POST',
        data: { name: name, mail: mail, subject: subject, text: text },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'plain-text'
        },
        url: './sendMail.php',
      };
      axios(config)
        .then(async (response) => {
          console.log('response',response);
          setName("");
          setMail("");
          setSubject("");
          setText("");
          document.location('www.google.com');
          //you can use here a state to set your data response
        })
        .catch((error) => {
          console.log(`error`, error.response);
        });



    } else {
      setEmailError('Enter valid Email!');
    }
  }

  const [isActive, setIsActive] = useState(false);

  const scaleUp = () => {
     setIsActive(true);
  }
  
  const scaleDown = () => {
    setIsActive(false);
  }

  const closeContact = () => {

    if (isActive)
    return;

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "none";
      }, 50);

  }


  return (
    <div id="contact" onClick={() => closeContact()}>
      <div id="contact-container">
        <div id="contact-center" className="w3-container contact-padding" >
        <div className="w3-card" style={{marginTop: "0px", padding: "2% 2% 2% 2%", transform: isActive ? "scale(1.01, 1.01)" : "scale(1.0, 1.0)", backgroundColor: "white"}} onMouseEnter={scaleUp} onMouseLeave={scaleDown}>
          <h1 style={{margin: "0px", padding: "0% 0% 0%", transform:"translateY(20px)"}} className="w3-center">CONTACT FORM</h1>
          <div style={{margin: "0px", padding: "0% 0% 0%"}}>
              <div style={{display: "block", justifyContent: "left"}}>
                <div>
                  <br></br>
                  <div style={{margin: "0px", padding: "0% 5% 0%"}}>
                    <form action="#" name="Form" onSubmit={handleSubmit(onSubmit, onError)}>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? nameEN : nameDE} required {...register("name")}  onChange={(e) => setName(e.target.value)}></input></p>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? emailEN : emailDE} required {...register("mail")}  onChange={(e) => setMail(e.target.value)}></input></p>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? subjectEN : subjectDE} required {...register("subject")}  onChange={(e) => setSubject(e.target.value)}></input></p>
                      <p ><textarea className="w3-input w3-border" rows="3" cols="60" type="text" required {...register("text")} placeholder={language === "en" ? textEN : textDE}  onChange={(e) => setText(e.target.value)}></textarea></p>
                      <br></br>
                      <span style={{
                        fontWeight: 'bold',
                        color: 'red',
                      }}>{emailError}</span>
                      <p style={{margin: "0px", padding: "0% 0% 0%", transform:"translateY(-20px)"}}>
                          <button id="submitBtn" className="w3-button" type="submit">
                          <i className="fa fa-paper-plane" ></i> SEND MESSAGE
                          </button>
                      </p>
                    </form>
                  </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Contact
