import { Link } from "react-router-dom"
import React from "react";
import { useEffect, useState } from "react"

import helmet0 from '../images/frames/HelmetOff_0.png';
import helmet1 from '../images/frames/HelmetOff_1.png';
import helmet2 from '../images/frames/HelmetOff_2.png';
import helmet3 from '../images/frames/HelmetOff_3.png';
import helmet4 from '../images/frames/HelmetOff_4.png';
import helmet5 from '../images/frames/HelmetOff_5.png';
import helmet6 from '../images/frames/HelmetOff_6.png';
import helmet7 from '../images/frames/HelmetOff_7.png';
import helmet8 from '../images/frames/HelmetOff_8.png';
import helmet9 from '../images/frames/HelmetOff_9.png';
import helmet10 from '../images/frames/HelmetOff_10.png';
import helmet11 from '../images/frames/HelmetOff_11.png';
import helmet12 from '../images/frames/HelmetOff_12.png';
import helmet13 from '../images/frames/HelmetOff_13.png';
import helmet14 from '../images/frames/HelmetOff_14.png';
import helmet15 from '../images/frames/HelmetOff_15.png';
import helmet16 from '../images/frames/HelmetOff_16.png';
import helmet17 from '../images/frames/HelmetOff_17.png';
import helmet18 from '../images/frames/HelmetOff_18.png';


const Footer = ({language}) => {

  const [selected, setSelected] = useState(helmet0);
  const [isSticky, setSticky] = useState("");

  useEffect(() => {
    const handleScroll = (event) => {
       const slides = [];
       slides.push(helmet0);
       slides.push(helmet0);
       slides.push(helmet1);
       slides.push(helmet2);
       slides.push(helmet3);
       slides.push(helmet4);
       slides.push(helmet5);
       slides.push(helmet6);
       slides.push(helmet7);
       slides.push(helmet8);
       slides.push(helmet9);
       slides.push(helmet10);
       slides.push(helmet11);
       slides.push(helmet12);
       slides.push(helmet13);
       slides.push(helmet14);
       slides.push(helmet15);
       slides.push(helmet16);
       slides.push(helmet17);
       slides.push(helmet18);

     const y = window.scrollY;
     const scrollPixels = Math.min(Math.floor(y/100) + 1, 18);
     const imageToUse = slides[scrollPixels];
     setSelected(imageToUse);
     setSticky(window.pageYOffset > 1100 ? "w3-center w3-padding-64 wow fadeIn w3-card sticky" : "w3-center w3-padding-64 wow fadeIn w3-card");
    };

    makeTriangle();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const makeTriangle = () => {
    var canvas = document.getElementById('canvas');
    var c = canvas.getContext('2d');
    c.fillStyle = 'white';
    c.beginPath();     //Begin a path..
    c.moveTo(50, 0);  //Startpoint (x, y)
    c.lineTo(100, 40); //Point 1    (x, y)
    c.lineTo(105, 0);  //Point 2    (x, y)
    c.closePath();     //Close the path.
    //Fill triangle with previous set color.
    c.fill();
    //Give triangle a stroke (width: 4 pixels).
    //c.strokeStyle = 'white'
    //c.lineWidth   = 1;
    //c.stroke();
  }


  const openContact = () => {

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "block";
      }, 50);

  }

  return (
  <footer className="w3-center w3-padding-64 w3-card sticky" style={{backgroundColor: "white"}}>
     <div id="footer-play-btn" style={{position:"absolute", transform:"translate(-230px, -200px)"}}>
        <div className="button-wrap" style={{transform:"scale(1.5)"}} data-wow-duration="2s" data-wow-delay="0.33s">
          <div>
          <a href="https://store.steampowered.com/app/2488780/Bionic_Momentum/"><button className="header-button"> PLAY THE GAME ! </button></a>
          </div>
        </div>
        <canvas id="canvas" width="200" height="200" style={{transform:"translateX(100px)", zIndex:"-999", position:"relative"}}></canvas>
      </div> 
    <p style={{paddingTop: "30px"}}><a href="#home" className="w3-button wow fadeIn"><i className="fa fa-arrow-up"></i>  Back To Top</a></p>
    <div style={{position: "relative", transform:"scaleX(-1)"}}>
      <img src={selected} className="no-highlight" style={{position:"absolute"}} id="main-logo2" alt="John" draggable="false"></img>
    </div>
    <div className="icon-outer-container2">
      <div className="icon-inner-container w3-xxlarge">
        <i className="fa fa-facebook-official w3-hover-opacity"></i>
        <i className="fa fa-instagram w3-hover-opacity"></i>
        <i className="fa fa-snapchat w3-hover-opacity"></i>
        <i className="fa fa-pinterest-p w3-hover-opacity"></i>
        <i className="fa fa-twitter w3-hover-opacity"></i>
        <i className="fa fa-linkedin w3-hover-opacity"></i>
      </div>
    </div>
    <div style={{textAlign: "center", transform: "translateY(-23px)", margin: 0, width:"100%"}}>
    <img className="company-logo" alt="Jane"></img>
      <h3 style={{transform: "translateY(10px)", margin:0}}>MAGNUMIZER Game Studio</h3>
      <span>All Rights Reserved © 2023</span>
    </div>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", transform: "translateY(10px)"}}>
      <div>
        <p onClick={() => openContact()} className="w3-bar-item w3-button"><i className="fa fa-envelope"></i> CONTACT</p>
      </div>
    </div>  
  </footer>
  )
}

export default Footer
