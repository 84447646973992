import { useState, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Header from "./components/Header"
/*import bgImage from "./images/bg.png"
import ContentSection from "./components/ContentSection"
import Pricing from "./components/Pricing"
import Skills from "./components/Skills"*/
import Footer from "./components/Footer"
import About from "./components/About"
import NavBar from "./components/NavBar"
import Overlay from "./components/Overlay"
import SideBar from "./components/SideBar"
import Stats from "./components/Stats"
import Design from "./components/Design"
import Work from "./components/Work"
import Modal from "./components/Modal"
import Contact from "./components/Contact"
import Team from "./components/Team"
import Divider from "./components/Divider"
import Impressum from "./components/Impressum"
import OrderNow from "./components/OrderNow"
import SlideShowBox from "./components/SlideShowBox"

function App() {

  // Modal Image Gallery
/*function onClick(element) {
  document.getElementById("img01").src = element.src;
  document.getElementById("modal01").style.display = "block";
  var captionText = document.getElementById("caption");
  captionText.innerHTML = element.alt;
}*/

var mySidebar = document.getElementById("mySidebar");

const openBar = () => {
  if (mySidebar.style.display === 'block') {
    mySidebar.style.display = 'none';
  } else {
    mySidebar.style.display = 'block';
  }
}

// Close the sidebar with the close button
const closeBar = () => {
    mySidebar.style.display = "none";
}

/*   const [showAddTask, setShowAddTask] = useState(false)

  const [tasks, setTasks] = useState([])

  useEffect(() => {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks()
      setTasks(tasksFromServer)
    }
    getTasks()
  }, [])

  const fetchTasks = async () => {
    const res = await fetch("http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/db.json")
    const data = await res.json()

    return data
  }

  const fetchTask = async (id) => {
    const res = await fetch(`http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/db.json/${id}`)
    const data = await res.json()

    return data
  }

  const addTask = async (task) => {
    const res = await fetch("http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/db.json", {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(task)
    })

    const data = await res.json()
    setTasks([...tasks, data])
  }

  const deleteTask = async (id) => {
    await fetch(`http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/db.json/${id}`, {
      method: "DELETE",
    })

    setTasks(tasks.filter((task) => task.id !== id))
  }

  const toggleReminder = async (id) => {
    const taskToToggle = await fetchTask(id)
    const updTask = { ...taskToToggle, reminder: !taskToToggle.reminder }
    const res = await fetch(`http://ec2-52-59-119-148.eu-central-1.compute.amazonaws.com/kavm/intosol/db.json/${id}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(updTask)
    })

    const data = await res.json()

    setTasks(tasks.map((task) => task.id === id ? { ...task, reminder: data.reminder} : task))
  } */

  useEffect(() => {
    window.setTimeout ( function() {  
      document.getElementById("overlay2").style.display = "none";
    }, 100);

    window.setTimeout ( function() {  
      var overlay = document.getElementById("overlay");
      overlay.classList.remove("wow");
      overlay.classList.remove("fadeOut");
    }, 5500);
  }, []);

  const [lang, setLang] = useState("en")
  const changeLang = (language) => {
    var overlay = document.getElementById("overlay");
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;
    overlay.style.display = "block";
    setLang(language);
    window.setTimeout ( function() { 
      window.scrollTo(scrollX, scrollY); 
      overlay.style.display = "none";
    }, 50);
  }

  return (
    <BrowserRouter>
    <div id="overlay2"></div>

    <div className="container">
     <NavBar setLanguage={changeLang} language={lang} openSideBar={openBar}/>
     <SideBar closeSideBar={closeBar} />
     <Routes>
     <Route path="/" element= {       
      <>
        <Overlay />
        <Header language={lang} />
        <Divider isWhite={true} />
        <Work language={lang} />
        <Divider isWhite={false}/>
        <Design />
        <Divider isWhite={false}/>
        <About language={lang} />
        <SlideShowBox />
        <Contact language={lang} />
        <Footer language={lang} />
      </>
      } />
     <Route path="/support" element={
      <Contact language={lang} />
     } />
     </Routes>
     {/*<br></br>*/}
    </div>
    </BrowserRouter>
  );
}

export default App;
