import React, { useState, useEffect } from 'react';

const Product = ({language, idNumber, title, imageSrc, contentText, measure, price, flipCard, show}) => {

  const amazonBuyEN = " Order now";
  const amazonBuyDE = " Jetzt bestellen";

  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = () => {
    let number = slideIndex;
    number++;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const minusSlides = () => {
    let number = slideIndex;
    number--;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const currentSlide = (n) => {
    setSlideIndex(n);
    showSlides(n);
  }

const showSlides = (n) => {
  let i;
  let numOfSlides = imageSrc.length;
  let number = 0 + n;

  const slides = [];
  for (i = 0; i < numOfSlides; i++) {
    slides.push(document.getElementById("slide" + i + "-" + idNumber));
  }

  const dots = [];
  for (i = 0; i < numOfSlides; i++) {
    dots.push(document.getElementById("dot" + i + "-" + idNumber));
  }

  if (n > numOfSlides) {
    setSlideIndex(1);
    number = 1;
  }    
  if (n < 1) {
    setSlideIndex(numOfSlides);
    number = numOfSlides;
  }
  for (i = 0; i < numOfSlides; i++) {
    slides[i].style.display = "none";  
  }
  for (i = 0; i < numOfSlides; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  number--;
  slides[number].style.display = "block";  
  dots[number].className += " active";
}

const openSlideShow = () => {

  window.setTimeout ( function() {  
    document.getElementById("slideshow-box").style.display = "block";
    }, 50);

}

  const [isActive, setIsActive] = useState(false);
  const scaleUp = () => {
     setIsActive(true);
  }
  const scaleDown = () => {
    setIsActive(false);
  }

  useEffect(() => {
    window.setTimeout ( function() {   
      currentSlide(0); 
      window.setTimeout ( function() {   
        currentSlide(1); 
      }, 100);
    }, 100);

  }, []);

  const makeDots = () => {
    const row = [];
    for (var i = 0; i < imageSrc.length; i++) {
      row.push(<span key={i} id={"dot" + i + "-" + idNumber} className="dot"></span> );
    }
    return row;
  };

  const makeSlides = () => {
    const row = [];
    for (var i = 0; i < imageSrc.length; i++) {
      row.push(<div key={i} id={"slide" + i + "-" + idNumber} className="mySlides fade">
      <img src={imageSrc[i]} className="w3-hover-opacity2" alt="Not found" style={{width: "100%", maxHeight: "420px"}}></img>
    </div> );
    }
    return row;
  };

  const makeContent = () => {
    const row = [];
    for (var i = 0; i < contentText.length; i++) {
      row.push(<div>
      <p className="cardback-content">{contentText[i]}</p> 
      </div> );
    }
    return row;
  };

  return (
    <div style={show ? activeStyle : inactiveStyle}>
        <div className="card-container" style={{transform: isActive ? "scale(1.01, 1.01)" : "scale(1.0, 1.0)"}} onMouseEnter={scaleUp} onMouseLeave={scaleDown}>
            <div onClick={(e) => flipCard(e, idNumber, true)} id={"card" + idNumber + "-1"} className="w3-card top-card">
              <h3 className="product-title">{title}</h3>
              <br></br>
              <div className="w3-container hoverShow">
                  <a className="prev showOnHover" onClick={() => minusSlides()}>❮</a>
                  <a className="next showOnHover" onClick={() => plusSlides()}>❯</a>

                <div className="product-image">
                  <br></br>
                  <div onClick={() => openSlideShow()} className="slideshow-container">

                    {
                      makeSlides()

                    /*<div id={"slide0-" + idNumber} className="mySlides fade">
                      <img src={imageSrc[0]} className="w3-hover-opacity" alt="Not found" style={{width: "100%", maxHeight: "320px"}}></img>
                    </div>

                    <div id={"slide1-" + idNumber} className="mySlides fade">
                      <img src={imageSrc[1]} className="w3-hover-opacity" alt="Not found" style={{width: "100%", maxHeight: "320px"}}></img>
                    </div>

                    <div id={"slide2-" + idNumber} className="mySlides fade">
                      <img src={imageSrc[2]} className="w3-hover-opacity" alt="Not found" style={{width: "100%", maxHeight: "320px"}}></img>
                    </div>*/
                    
                    }


                  </div>
                  <br></br>
                  <div className="showOnHover" style={{position: "absolute", textAlign: "center", bottom: "15px", left: "30%", right: "30%"}}>
                  {
                    makeDots()
                  /*<span id={"dot0-" + idNumber} className="dot" onClick={() => currentSlide(1)}></span> 
                  <span id={"dot1-" + idNumber} className="dot" onClick={() => currentSlide(2)}></span> 
                  <span id={"dot2-" + idNumber} className="dot" onClick={() => currentSlide(3)}></span> */
                  }
                </div>
                </div>
              </div>
              <div>
                <span style={{fontSize:"9px"}}>click to learn more</span>
              </div>
            </div>
            <div onClick={(e) => flipCard(e, idNumber, false)} id={"card" + idNumber + "-2"} className="w3-card bottom-card" style={{opacity: "0%"}}>
                <div style={{backgroundColor: "white"}}>
                    <div className="w3-container">
                        <h3 className="card-title">{title}</h3>
                        <div className="w3-container" style={{position: "relative", display: "flex", alignItems: "normal", paddingTop: 0, marginTop: 0}}>
                          <div style={{textAlign: "left", position: "absolute", paddingRight: "5px", marginTop:"0px"}}>
                            {
                              makeContent()
                            }
                          </div>
                        </div>
                    </div>
                </div>
                    {/*<p style={{position: "absolute", bottom: 0, right: 10}}>Price: {price}€</p>*/}
            </div>
        </div>
    </div>
  )
}

const activeStyle = {
    display: "block"
  }
  
  const inactiveStyle = {
    display: "none"
  }

export default Product
