const Design = () => {

  return (
    <div className="w3-center" style={{backgroundColor:"black"}}>
      <iframe id="youtube-trailer" src="https://www.youtube.com/embed/fbHVS2Ak8JI"></iframe>
    </div>
  )
}

export default Design
