//import { useLocation } from "react-router-dom"

const Header = ({ title, language }) => {
  //const location = useLocation()


  return (
      <div className="w3-display-container" id="home" style={{backgroundColor:"black"}}>
        <div className="header-parent">
          <div className="bgimg-1">
          </div>
        </div>

        <div className="w3-display-title w3-text-white">
          <div className="header-content-wrapper">  
          </div>
          <div>
            <img className="no-highlight" id="main-logo" alt="John" draggable="false"></img>
          </div>
        </div> 
        
        <section className="wow" data-wow-duration="3s" data-wow-delay="0s" style={{display: "none"}}>
        <div className="w3-display-bottomright w3-text-grey w3-xxlarge icon-container" style={{padding: 218+"px " + 48+"px"}}>
          <i className="fa fa-facebook-official w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="2.5s"></i>
          <i className="fa fa-instagram w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="2.25s"></i>
          <i className="fa fa-snapchat w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="2s"></i>
          <i className="fa fa-pinterest-p w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="1.75s"></i>
          <i className="fa fa-twitter w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="1.5s"></i>
          <i className="fa fa-linkedin w3-hover-opacity theme-color" data-wow-duration="3s" data-wow-delay="1.0s"></i>
        </div>
        </section>
      </div>
  )
} 

Header.defaultProps = {
    title: "Bionic Momentum"
}

/*const headingStyle = { w3-button w3-green w3-padding-large w3-large w3-margin-top
  height: 100 + "%", 
  width: 100 + "%",
  objectFit: "contain"
}*/

export default Header
