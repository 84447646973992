const Divider = ({isWhite}) => {
  return (
    <div className="divider" style={isWhite ? bgColorWhite : bgColorBlack}>
      
    </div>
  )
}

const bgColorWhite = {
  backgroundColor: "white"
}

const bgColorBlack = {
  backgroundColor: "black"
}
export default Divider
