const NavBar = ({openSideBar}) => {

  const openContact = () => {

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "block";
      }, 50);

  }

  return (
    <div className="navbar">
      <div className="w3-bar w3-white w3-card">

        <a href="#home"  className="w3-bar-item w3-button w3-wide">
          <img id="navbar-logo" alt="adda"></img>
          <span className="title-font-navbar" style={{paddingLeft: "40px"}}>Bionic Momentum</span>
        </a>

        <div className="w3-right">
          <a id="play-corner-big" href="https://store.steampowered.com/app/2488780/Bionic_Momentum/" className="w3-bar-item w3-button"> PLAY THE GAME   </a>
          <a id="play-corner-small" href="https://store.steampowered.com/app/2488780/Bionic_Momentum/" className="w3-bar-item w3-button"> PLAY </a>
          {/*<a onClick={() => openContact()} className="w3-bar-item w3-button"> CONTACT INFO</a> */}
        </div> 
      </div>
    </div>
  )
}

const activeStyle = {
  textDecorationLine: "none",
  textDecorationStyle: "solid",
  textDecorationColor: "grey",
  textDecorationThickness: "1px",
  fontWeight: "bold",
  letterSpacing: "1px",
  padding: "10%",
  textAlign: "center"
}

const inactiveStyle = {
  textDecorationLine: "none",
  textDecorationStyle: "none",
  textDecorationColor: "black",
  textDecorationThickness: "0px",
  textShadow: "none",
  fontWeight: "normal",
  letterSpacing: "0px",
  padding: "10%",
  textAlign: "center"
}

export default NavBar
