import { useState } from "react";
import {useRef} from 'react';
import Product from "./Product";

import slide1 from "../images/slides/1.jpg";
import slide2 from "../images/slides/2.jpg";
import slide3 from "../images/slides/3.jpg";
import slide4 from "../images/slides/4.jpg";
import slide5 from "../images/slides/5.jpg";
import slide6 from "../images/slides/6.jpg";
import slide7 from "../images/slides/7.jpg";
import slide8 from "../images/slides/8.jpg";
import slide9 from "../images/slides/9.jpg";
import slide10 from "../images/slides/10.jpg";
import slide11 from "../images/slides/11.jpg";
import slide12 from "../images/slides/12.jpg";
import slide13 from "../images/slides/13.jpg";
import slide14 from "../images/slides/14.jpg";
import slide15 from "../images/slides/15.jpg";
import slide16 from "../images/slides/16.jpg";
import slide17 from "../images/slides/17.jpg";
import slide18 from "../images/slides/18.jpg";
import slide19 from "../images/slides/19.jpg";
import slide20 from "../images/slides/20.jpg";
import slide21 from "../images/slides/21.jpg";
import slide22 from "../images/slides/22.jpg";

const Work = ({language}) => {

  const ref = useRef(null);

  const [animating, setAnimating] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const toggleAnimating = (index) => {
    setAnimating(prevState => prevState.map((item, idx) => idx === index ? !item : item))
  };

  const flipCard = (e, cardNumber, flipIn) => {

    if (e.target instanceof HTMLButtonElement || e.target instanceof HTMLAnchorElement || e.target instanceof HTMLImageElement)
    {
        return;
    } 

    const card = document.getElementById("card" + cardNumber + "-1");
    const card2 = document.getElementById("card" + cardNumber + "-2");
    const flipSpeedMS = 400;

    if (animating[cardNumber - 1]) {
        return;
    }
    
    toggleAnimating(cardNumber - 1);
    window.setTimeout ( function() {  
      toggleAnimating(cardNumber - 1);
      }, flipSpeedMS);

    if(flipIn) {
        card.classList = "flip-style w3-card top-card"
        card2.classList = "flip-style2 w3-card bottom-card"
        window.setTimeout ( function() {  
            card.classList = "flip-style w3-card bottom-card"
            card.style.opacity = "0%";
            card2.classList = "flip-style2 w3-card top-card"   
            card2.style.opacity = "99%";  
          }, flipSpeedMS / 2);
        window.setTimeout ( function() {   
            card.classList = "w3-card bottom-card"
            card2.classList = "w3-card top-card" 
          }, flipSpeedMS);
    } else {
        card.classList = "flip-style2 w3-card top-card"
        card2.classList = "flip-style w3-card bottom-card"
        window.setTimeout ( function() {  
            card.classList = "flip-style2 w3-card bottom-card"
            card.style.opacity = "99%";
            card2.classList = "flip-style w3-card top-card"   
            card2.style.opacity = "0%"; 
          }, flipSpeedMS / 2);
        window.setTimeout ( function() {   
            card.classList = "w3-card top-card"
            card2.classList = "w3-card bottom-card" 
          }, flipSpeedMS);
    }
}

const initialVisibleAreas = {
  'kitchen': true,
  'animal': true,
  'decor': true,
  'other': true
};

const [visibleAreas, setVisibleAreas] = useState(initialVisibleAreas);

const showCategory = (category) => {

  setVisibleAreas({  
    'kitchen': category === 'kitchen' ? true : false,
    'animal': category === 'animal' ? true : false,
    'decor': category === 'decor' ? true : false,
    'other': category === 'other' ? true : false
  })

  for (let i = 0; i < 5; i++) {
    document.getElementById('cat-list').getElementsByTagName('span')[i].style.fontWeight = "normal";
  }

  switch (category) {
    case 'kitchen':
      document.getElementById('cat-list').getElementsByTagName('span')[0].style.fontWeight = "bold";
    break;
    case 'animal':
      document.getElementById('cat-list').getElementsByTagName('span')[1].style.fontWeight = "bold";
    break;
    case 'decor':
      document.getElementById('cat-list').getElementsByTagName('span')[2].style.fontWeight = "bold";
    break;
    case 'other':
      document.getElementById('cat-list').getElementsByTagName('span')[3].style.fontWeight = "bold";
    break;
    default:
      break;
  }

  window.setTimeout ( function() {   
    ref.current.checked = false;
  }, 100);
}

const showAllCategories = () => {

  const allTrue = Object.assign(
    ...Object.keys(visibleAreas).map((key) => ({ [key]: true }))
  );

  setVisibleAreas(allTrue);

  for (let i = 0; i < 5; i++) {
    document.getElementById('cat-list').getElementsByTagName('span')[i].style.fontWeight = "normal";
  }
  document.getElementById('cat-list').getElementsByTagName('span')[4].style.fontWeight = "bold";
  
  window.setTimeout ( function() {   
    ref.current.checked = false;
  }, 100);
}

const hideCatBar = (e) => {
  if (ref.current.checked && e.target instanceof HTMLDivElement) {
    ref.current.checked = false;
  }
  //console.log('✅ Checkbox is checked');
  //console.log('⛔️ Checkbox is NOT checked');
}

  return (
  <div className="w3-container2" style={{padding: "28px 16px"}}>

    <div style={{position:"relative"}}>
        <p className="w3-center describe-text">Bionic Momentum is a 2D Physics-based Action Puzzle Platform game developed for PC and Xbox</p>
        {/*<p className="w3-center describe-text" style={{}}>With an emphasis on precise movement, building up speed and maintaining momentum while using abilities to conquer enemies and obstacles</p>*/}
    </div>

    <div className="w3-image2 gradient-fade-test" id="work">
      <div id="product-wrapper" style={{paddingBottom: "220px"}}>
        <div className="product-container">

            <Product 
            language={language}
            idNumber={1} 
            title={"Compelling single-player adventure"} 
            imageSrc={[slide3, slide2, slide1, slide10, slide11, slide12, slide20 ]}
            contentText={["Play as a young man gifted with a bionic suit that turns him into a fast and deadly weapon.", "Progress through an exciting adventure and discover many atmospheric and cool worlds as you battle your way past enemies and obstacles.", "Fight alone against the darkness and save the world from an evil being that wants to control the universe.", "No one stands a chance except you..."]} 
            flipCard={flipCard}
            show={visibleAreas['decor'] || visibleAreas['other']} />

            <Product 
            language={language}
            idNumber={2} 
            title={"Fast-paced action with emphasis on speed & momentum"} 
            imageSrc={[slide19, slide5, slide6, slide13, slide14, slide21, slide18, slide22 ]}
            contentText={["Use extremely precise movement with tight jump timings and accurate controls to make it across vast canyons and lakes.", "It is important to retain momentum, or you might not have enough speed for some of the bigger jumps.", "Sometimes you will have to go back and start again all the way from the beginning...", "Many of the obstacle courses you need to get through can be very challenging!"]} 
            flipCard={flipCard}
            show={visibleAreas['kitchen']} />

            <Product 
            language={language}
            idNumber={3} 
            title={"Challenging levels, hard bosses and many unique abilities!"} 
            imageSrc={[ slide7, slide15, slide8, slide9, slide16, slide17, slide4 ]}
            contentText={["Discover more than 50+ levels that become progressively challenging.", "Do you have what it takes to finish the game?", "You will need to practice your skills to make it past some of the harder levels.", "As you progress through the levels, you unlock new abilities to help you out along the way.", "But fair warning, the game gets increasingly difficult toward the end!"]} 
            flipCard={flipCard}
            show={visibleAreas['kitchen']} />

        </div>
      </div>
      <div style={{paddingBottom:"130px"}}>
        <div className="button-wrap play-btn" data-wow-duration="2s" data-wow-delay="0.33s">
          <div>
          <a href="https://store.steampowered.com/app/2488780/Bionic_Momentum/"><button className="header-button"> PLAY THE GAME </button></a>
          </div>
        </div>
      </div> 
    </div>
  </div>
  )
}

export default Work
